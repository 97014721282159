import {
    Dispatch,
    ReactNode,
    createContext,
    useContext,
    useMemo,
    useReducer,
} from 'react'
import {
    reducer,
    initialState,
    ProgressDataState,
    ProgressDataAction,
} from './progress-data-reducer'

interface ProgressDataContext {
    state: ProgressDataState
    dispatch: Dispatch<ProgressDataAction>
}

const ProgressData = createContext<ProgressDataContext | null>(null)

// Warn if hook is used outside of provider children
export const useProgressData = () => {
    const progressDataContext = useContext(ProgressData)
    if (!progressDataContext)
        throw new ReferenceError(
            'You must call useProgressData from within ProgressDataProvider.'
        )
    return progressDataContext
}

export interface ProgressDataProviderProps {
    children: ReactNode
}

export const ProgressDataProvider = ({
    children,
}: ProgressDataProviderProps) => {
    const [state, dispatch] = useReducer(reducer, initialState)

    // Memoizing the values to avoid frequent rerenders since
    // the provider will be in the top level App component
    const contextValue = useMemo(() => {
        return { state, dispatch }
    }, [state, dispatch])

    return (
        <ProgressData.Provider value={contextValue}>
            {children}
        </ProgressData.Provider>
    )
}
