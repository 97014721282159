import { LandingPage } from '../pages/landing-page'
import { ProgressDataProvider } from '../providers/progress-data-provider'
import { Provider as ReduxProvider } from 'react-redux'
import { ModalProvider } from '@codecorp/components'
import { store } from '../store'

export function App() {
    return (
        <div className="flex justify-center overflow-y-auto">
            <ReduxProvider store={store}>
                <ProgressDataProvider>
                    <ModalProvider>
                        <LandingPage />
                    </ModalProvider>
                </ProgressDataProvider>
            </ReduxProvider>
        </div>
    )
}

export default App
