import * as base from '@radix-ui/react-radio-group'
import clsx from 'clsx'
import { ComponentRef, forwardRef, useId } from 'react'
import { Props } from '../../types'
import { Label } from '@radix-ui/react-label'

export type RadioButtonProps = Props<
    Record<never, never>,
    base.RadioGroupItemProps
>

export const RadioButton = forwardRef<
    ComponentRef<typeof base.Item>,
    RadioButtonProps
>(({ className, children, id: idProp, ...props }, ref) => {
    // have to unconditionally call hook, but also want to use the id from props when given
    const idGen = `radio-button-${useId()}`
    const id = idProp ?? idGen

    return (
        <div className={clsx`${className} flex items-center`}>
            <base.Item
                id={id}
                {...props}
                ref={ref}
                className="radix-state-checked:border-brand-red flex aspect-square w-5 cursor-default items-center justify-center rounded-full border-2 border-solid border-gray-700 bg-white hover:bg-red-100"
            >
                <base.Indicator className="bg-brand-red flex aspect-square w-3 rounded-full" />
            </base.Item>
            <Label
                htmlFor={id}
                className="ml-3"
            >
                {children}
            </Label>
        </div>
    )
})
