import { TextInput } from '@codecorp/components'
import { MagnifyingGlassIcon } from '@radix-ui/react-icons'
import { ChangeEventHandler } from 'react'
import { clsx } from 'clsx'

export interface SearchBarProps {
    onChange: ChangeEventHandler<HTMLInputElement>
    className?: string
}

export const SearchBar = ({ onChange, className }: SearchBarProps) => {
    return (
        <div className={clsx('relative flex w-2/3 flex-col', className)}>
            <label className="mb-1 font-bold">Keywords</label>
            <MagnifyingGlassIcon
                className="absolute top-10 z-10 ml-2"
                width={20}
                height={20}
            />
            <TextInput
                className="border-2 border-gray-300 pl-8"
                placeholder="Search keywords here..."
                onChange={onChange}
            />
        </div>
    )
}
