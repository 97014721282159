import { throw_ } from '../expression-throw'

export type FormatArgs<TFormat extends string> =
    TFormat extends `${string}{${infer R}}${infer U}`
        ? R | FormatArgs<U>
        : never

const placeholderRegex = /{(\w+)}/g

/**
 * Formats a string by replacing placeholders with values from an object.
 * @param formatString The string to format.
 * @param args An object containing key-value pairs to replace placeholders in the string.
 * @returns The formatted string.
 * @example
 * // Returns "Hello, John!"
 * format("Hello, {name}!", { name: "John" });
 * @example
 * // Returns "The answer is 42."
 * format("The answer is {answer}.", { answer: 42 });
 */
export const format = <const T extends string>(
    formatString: T,
    args: Record<FormatArgs<T>, unknown>
) =>
    formatString.replace(placeholderRegex, (_match, key: FormatArgs<T>) =>
        Object.hasOwn(args, key)
            ? `${args[key]}`
            : throw_(new Error(`Missing argument for ${key}`))
    )

const regexFromFormatStr = (formatString: string) =>
    new RegExp(
        `^${
            //
            formatString
                // modified from https://developer.mozilla.org/en-US/docs/Web/JavaScript/Guide/Regular_Expressions#escaping
                // notably does not escape { or } because we are replacing them later
                .replace(/[.*+?^$()|[\]\\]/g, '\\$&')
                .replace(placeholderRegex, '(?<$1>.*)')
        }$`
    )

/**
 * Tests if a string matches a format string.
 * @param formatString The format string to match against.
 * @param testString The string to test.
 * @returns A boolean indicating whether the test string matches the format string.
 * @example
 * // Returns true
 * match("Hello, {name}!", "Hello, John!");
 * @example
 * // Returns false
 * match("Goodbye, {name}!", "Hello, world!");
 */
export const match = (formatString: string, testString: string) =>
    regexFromFormatStr(formatString).test(testString)

export const getPlaceholderValues = <const T extends string>(
    formatString: T,
    testString: string
): Record<FormatArgs<T>, string> | null => {
    const match = testString.match(regexFromFormatStr(formatString))
    return match == null
        ? null
        : ({
              // groups has a null proto, so spread it into a real object since that's annoying in tests
              ...(match.groups ?? {}),
          } as Record<FormatArgs<T>, string>)
}
