import { ConfigQrCode } from '@codecorp/advanced-configuration'
import { abbreviateDeviceName } from '../utils/device'
import { Cross1Icon, ColumnSpacingIcon } from '@radix-ui/react-icons'
import { Setting } from './settings-list-content'
import { IndividualBarcodesCard } from './individual-barcodes-card'
import { useState } from 'react'
import { ProductDto } from '@codecorp/web-api-wrapper'

export interface BarcodeDialogProps {
    settings: Setting[]
    selectedDevice: ProductDto
    showSwitchButton?: boolean
}

export const BarcodeDialog = ({
    settings,
    selectedDevice,
    showSwitchButton = false,
}: BarcodeDialogProps) => {
    const [showIndividualBarcodes, setShowIndividualBarcodes] = useState(false)

    const isMaxSettingsAmount = settings.length > 30

    return (
        <div className="relative flex items-center justify-center">
            {showSwitchButton && !isMaxSettingsAmount && (
                <button
                    className="absolute top-4 right-16 z-20 flex h-8 w-8 items-center justify-center"
                    type="button"
                    title="Switch View"
                    onClick={() =>
                        setShowIndividualBarcodes(!showIndividualBarcodes)
                    }
                >
                    <ColumnSpacingIcon
                        width={22}
                        height={22}
                    />
                </button>
            )}
            <button
                className="absolute top-4 right-6 z-20 flex h-8 w-8 items-center justify-center"
                type="submit"
                title="Close"
                onClick={() => setShowIndividualBarcodes(false)}
            >
                <Cross1Icon
                    width={22}
                    height={22}
                />
            </button>
            {(showIndividualBarcodes || isMaxSettingsAmount) && (
                <IndividualBarcodesCard
                    settings={settings}
                    selectedDevice={selectedDevice}
                />
            )}
            {!showIndividualBarcodes && !isMaxSettingsAmount && (
                <ConfigQrCode
                    className="h-full w-full p-4"
                    width={160}
                    filename={selectedDevice.title}
                    commands={settings.flatMap(
                        (setting) => setting.sourceString
                    )}
                    description={
                        <div className="flex max-h-32 flex-col">
                            <p className="mb-2 text-center font-bold">
                                {abbreviateDeviceName(selectedDevice.title)}
                            </p>
                            <ul className="max-h-44 overflow-y-auto">
                                {settings.map((setting) => {
                                    return (
                                        <li key={setting.code}>
                                            • {setting.description}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                    }
                />
            )}
        </div>
    )
}
