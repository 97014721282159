import { Device } from '../device'

const separator = '\x03'
export const formatCmdsListForQrCode = (...commands: string[]) =>
    `\x01Y\x1d\x02${commands.join(separator)}${separator}\x04`

const deviceSpecificCfgCmds: Partial<Record<Device, string[]>> = {
    CR2700: [
        // has problems with long input if not slowed down
        'BTBRXCM[BTUBPRL5]',
        'CFG',
        // 'BTBRXCM[BTUBPRL0]',
        // if we reset it back to 0 it can happen before we're done printing the cfg out
        // https://codecorp.atlassian.net/browse/FWARCH-5
    ],
    'A271/2': [
        'BTBRXCM[BTCMPRD1]',
        'BTBRXCM[CMCPSPM1]',
        'BTBRXCM[CFG]',
        'BTBRXCM[CMCPSPM0]',
        'BTBRXCM[BTCMPRD0]',
    ],
}

const defaultCfgCmd = ['CMCPSPM1', 'CFG', 'CMCPSPM0']

export const cfgForDevice = (device: Device) =>
    formatCmdsListForQrCode(...(deviceSpecificCfgCmds[device] ?? defaultCfgCmd))
