export const throw_ = (e: Error) => {
    throw e
}

export interface NotNullOptions {
    msg?: string
}

export const notNull = <T>(
    x: T,
    { msg = 'Argument was null' }: NotNullOptions = {}
): NonNullable<T> => x ?? throw_(new Error(msg))
