import * as base from '@radix-ui/react-select'
import { ChevronRightIcon } from '@radix-ui/react-icons'
import { ReactNode, Ref, forwardRef } from 'react'
import { Props } from '../types'
import clsx, { ClassValue } from 'clsx'
import { useElementsWithWhichPopperShouldAvoidCollisions } from './popper-collision-hooks'

export interface SelectItem<T extends string> {
    label: ReactNode
    value: T
}

export type SelectProps<T extends string> = Props<{
    items: readonly SelectItem<T>[]
    placeholder?: string
    value?: T | null
    onValueChange?: (selectedItem: T) => void
    small?: boolean
}> &
    Omit<base.SelectProps, 'value'>

export interface SelectParentProps extends base.SelectProps {
    placeholder?: ReactNode
    small?: boolean
    className?: ClassValue
}

export const SelectParent = forwardRef(
    (
        {
            placeholder,
            small,
            children,
            className,
            ...props
        }: SelectParentProps,
        forwardedRef: Ref<HTMLButtonElement>
    ) => (
        <base.Root {...props}>
            <base.Trigger
                ref={forwardedRef}
                className={clsx(
                    'drop-shadow-ring radix-placeholder:text-gray-300 radix-state-open:rounded-b-none group inline-flex items-center justify-between gap-2 bg-white leading-none text-gray-700 disabled:opacity-50 dark:bg-gray-600 dark:text-gray-200',
                    small
                        ? 'rounded-md px-3 py-2 text-sm'
                        : 'rounded-xl px-6 py-4 text-2xl',
                    className
                )}
            >
                <base.Value placeholder={placeholder} />
                <base.Icon>
                    <ChevronRightIcon
                        width={30}
                        height={30}
                        className="group-radix-state-open:rotate-90 transform text-gray-500 transition duration-100"
                    />
                </base.Icon>
            </base.Trigger>

            <base.Portal>
                <base.Content
                    position="popper"
                    className="w-radix-select-trigger-width drop-shadow-ring max-h-radix-select-content-available-height max-w-radix-select-content-available-width rounded-b-xl bg-white dark:bg-gray-600"
                    collisionBoundary={useElementsWithWhichPopperShouldAvoidCollisions()}
                >
                    <base.Viewport>{children}</base.Viewport>
                </base.Content>
            </base.Portal>
        </base.Root>
    )
)

export interface SelectItemProps<T extends string> {
    children?: ReactNode
    small?: boolean
    value: T
}
export const SelectItem = forwardRef(
    <T extends string>(
        { children, small, ...props }: SelectItemProps<T>,
        forwardedRef: Ref<HTMLDivElement>
    ) => (
        <base.Item
            {...props}
            ref={forwardedRef}
            className={clsx(
                'radix-highlighted:bg-sky-300 dark:radix-highlighted:bg-sky-700 radix-state-checked:font-black radix-state-checked:text-black dark:radix-state-checked:text-gray-200 m-3 flex w-full cursor-pointer select-none items-center justify-center font-medium text-gray-700 focus-visible:outline-none dark:text-gray-400',
                small ? 'px-3 py-2 text-sm' : 'px-6 py-4 text-lg'
            )}
        >
            <base.ItemText asChild>
                <span>{children}</span>
            </base.ItemText>
        </base.Item>
    )
)

export const Select = forwardRef(
    <T extends string>(
        { items, value, ...props }: SelectProps<T>,
        forwardedRef: Ref<HTMLButtonElement>
    ) => (
        <SelectParent
            {...props}
            // normally undefined would be reserved for using the component uncontrolled, but it seems like that's what it's using to mean "no value" to show placeholder
            value={value ?? undefined}
            ref={forwardedRef}
        >
            {items.map(({ label, value }) => (
                <SelectItem
                    key={value}
                    value={value}
                >
                    {label}
                </SelectItem>
            ))}
        </SelectParent>
    )
)
