export type Entries<T> = NonNullable<
    {
        [K in keyof T]: [K, T[K]]
    }[keyof T]
>[]

/**
 * For the common case of Object.entries where T is known to be the exact type,
 * not a supertype
 */
export const exactEntries = <T extends object>(obj: T) =>
    Object.entries(obj) as Entries<T>
