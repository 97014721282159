import qrCodeGen, { QRCodeRenderersOptions } from 'qrcode'
import { forwardRef, Ref, useEffect, useRef } from 'react'
import { useMergedRefs } from '../hooks/use-merged-refs'

export interface QrCodeProps extends QRCodeRenderersOptions {
    data: string
    label?: string
}

export const QrCode = forwardRef(
    ({ data, label, ...options }: QrCodeProps, ref: Ref<HTMLCanvasElement>) => {
        const canvasRef = useRef<HTMLCanvasElement>(null)
        const mergedRefs = useMergedRefs(ref, canvasRef)

        useEffect(() => {
            const canvas = canvasRef.current
            if (!canvas) return

            const ctx = canvas.getContext('2d')
            if (!ctx) {
                throw new Error('Failed to get 2D context from the canvas')
            }

            const labelFontSize = 18 // Set font size for the label
            const paddingBetweenQRAndLabel = 15 // Set padding between QR code and label

            // Draw the QR code at the top part of the canvas
            qrCodeGen.toCanvas(canvas, data, { ...options, margin: 10 }, () => {
                // After QR code is drawn, add the label if provided
                if (label) {
                    ctx.font = `${labelFontSize}px Arial` // Set font size and style
                    ctx.fillStyle = '#000' // Set text color
                    ctx.textAlign = 'center' // Center align the text

                    // Calculate the y-position for the label with padding
                    const labelYPosition =
                        canvas.width - paddingBetweenQRAndLabel

                    // Draw the label below the QR code
                    ctx.fillText(
                        label,
                        canvas.width / 2, // Center the label horizontally
                        labelYPosition, // Position the label vertically with padding
                        canvas.width // Max width for the text
                    )
                }
            })
        }, [data, label, options])

        return <canvas ref={mergedRefs} />
    }
)
