import { Checkbox, useModal } from '@codecorp/components'
import { MouseEvent, useRef } from 'react'
import { Setting } from './settings-list-content'
import { clsx } from 'clsx'
import { useProgressData } from '../providers/progress-data-provider'
import { QrClickIcon } from '@codecorp/components'
import { BarcodeDialog } from './barcode-dialog'
import { useButton } from '@react-aria/button'

export interface SettingsListCardProps {
    setting: Setting
}

export const SettingsListCard = ({ setting }: SettingsListCardProps) => {
    const { state, dispatch } = useProgressData()
    const { show } = useModal()
    const ref = useRef(null)
    const { buttonProps } = useButton({ elementType: 'li' }, ref)

    const handleSelect = () => {
        if (state.settings.has(setting)) {
            dispatch({ type: 'settingDeselected', setting })
        } else {
            dispatch({ type: 'settingSelected', setting })
        }
    }

    return (
        <li
            ref={ref}
            {...buttonProps}
            className={clsx(
                'flex h-fit w-full items-center justify-between rounded-md border-2 py-2 lg:h-16',
                setting.isDefault ? 'border-brand-red' : 'border-gray-300',
                state.settings.has(setting)
                    ? 'bg-brand-red bg-opacity-10'
                    : 'bg-white bg-opacity-100'
            )}
            onClick={handleSelect}
        >
            <div className="flex items-center text-xs md:text-sm lg:text-base">
                {/*Changing the dots between the code and revision to _ */}
                <p className="ml-6">{`${setting.code}_${setting.revision}`}</p>
                <p className="ml-5 md:ml-14">{setting.description}</p>
            </div>
            <div className="flex items-center">
                <button
                    className="ml-2 mr-8 w-7 md:mr-16"
                    onClick={(event: MouseEvent) => {
                        if (state.device === null) return
                        show({
                            content: (
                                <BarcodeDialog
                                    settings={[setting]}
                                    selectedDevice={state.device}
                                />
                            ),
                            modal: true,
                            dialogClassName: 'sm:w-1/3 sm:h-2/3',
                        })
                        event.stopPropagation()
                    }}
                >
                    <QrClickIcon
                        width={42}
                        height={42}
                    />
                </button>
                <Checkbox
                    className="mr-7 h-5 w-5"
                    checked={state.settings.has(setting)}
                />
            </div>
        </li>
    )
}
