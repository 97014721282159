import clsx from 'clsx'
import { BaseProps } from '../types'
import { ComponentPropsWithoutRef, forwardRef, useEffect, useRef } from 'react'
import { useMergedRefs } from '../hooks/use-merged-refs'

export type TextInputProps = Omit<ComponentPropsWithoutRef<'input'>, 'type'> &
    BaseProps & {
        large?: boolean
        customValidity?: string
        // all of the textlike input types
        type?: 'text' | 'email' | 'number' | 'password' | 'search' | 'url'
    }

export const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
    (
        { className, large, type = 'text', customValidity = '', ...props },
        forwardedRef
    ) => {
        const ref = useRef<HTMLInputElement>(null)
        const combinedRef = useMergedRefs(forwardedRef, ref)
        useEffect(() => {
            if (!ref.current) return

            ref.current.setCustomValidity(customValidity)
        }, [customValidity])

        return (
            <input
                ref={combinedRef}
                {...props}
                type={type}
                className={clsx(
                    className,
                    'font-condensed drop-shadow-ring rounded-md invalid:border-pink-500 invalid:text-pink-600 focus:invalid:border-pink-500 focus:invalid:ring-pink-500 dark:bg-gray-600 dark:text-gray-200',
                    large && 'h-14 px-6'
                )}
            />
        )
    }
)

export default TextInput
