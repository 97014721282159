// reexport everything
import '@codecorp/util/polyfills'
import { exactEntries } from '@codecorp/util/type/exact-entries'

export * from './generated-api'
export { setFetch } from './config'
export * from './utility-hooks'
import { components } from '../code-web-api.json'
export const scopes = exactEntries(
    components.securitySchemes.Auth0.flows.authorizationCode.scopes
).map(([scope]) => scope)

export { HotReload } from './dev/api-hot-reload'
