import clsx from 'clsx'
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import type { BaseProps } from '../types'
import { useButton, AriaButtonProps } from '@react-aria/button'
import { useRef } from 'react'

const DefaultButton = 'button'

export interface ButtonOwnProps extends BaseProps {
    /**
     * Should the button have a primary color background, or inherit background
     */
    filled?: boolean

    /**
     * Does the button have a shadow. Default enabled on not filled buttons
     */
    shadow?: boolean

    big?: boolean

    secondary?: boolean

    fixedWidth?: boolean

    /**
     * @deprecated Use onPress instead, it supports and normalizes all click/touch/keyboard activation events
     */
    // mark as unknown since T&unknown is T so it has no effect on the type
    // but have an entry to mark as deprecated
    onClick?: unknown

    /**
     * @deprecated Use isDisabled instead
     */
    // useButton uses isDisabled, but disabled would otherwise be valid since it's a DOM attribute
    disabled?: never
}

export type ButtonProps<T extends React.ElementType> =
    PolymorphicPropsWithoutRef<ButtonOwnProps, T> & AriaButtonProps<T>

export const Button = <
    TButton extends React.ElementType = typeof DefaultButton
>({
    // need forwarded
    as,
    ...props
}: ButtonProps<TButton>) => {
    const {
        filled,
        shadow,
        className,
        big,
        secondary,
        fixedWidth,
        children,
        onPress: _,
        isDisabled,
        ...baseElProps
    } = props

    const Element = as ?? DefaultButton
    const ref = useRef(null)
    const { buttonProps } = useButton({ ...props, elementType: Element }, ref)

    return (
        <Element
            {...baseElProps}
            {...buttonProps}
            ref={ref}
            className={clsx(
                filled
                    ? [
                          !secondary ? 'bg-brand-red' : 'bg-gray-500',
                          'text-white',
                      ]
                    : 'bg-white text-black dark:bg-slate-700 dark:text-white',
                big ? 'rounded-2xl' : 'rounded-md',
                {
                    'drop-shadow-ring': shadow ?? !filled,
                    'w-56': fixedWidth,
                    'pointer-events-none opacity-50': isDisabled,
                },
                'inline-block p-2 text-center',
                className
            )}
        >
            {children}
        </Element>
    )
}

export default Button
