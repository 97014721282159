import { Panel, prose } from '@codecorp/components'
import { SavedConfiguration } from './saved-configuration'
import { SavedConfigurationItem } from './saved-configuration-item'
import { clsx } from 'clsx'
import { ConfigHierarchy } from './config-options'
import { Link } from 'react-router-dom'

export interface ConfigurationLibraryProps {
    savedConfigurations: SavedConfiguration[]
    onDelete?: (configuration: SavedConfiguration) => void
    configHierarchy: ConfigHierarchy
}
const headerClassName = clsx('uppercase font-bold h-20 row-start-1')
export const ConfigurationLibrary = ({
    savedConfigurations,
    onDelete,

    configHierarchy,
}: ConfigurationLibraryProps) => (
    <div className="relative m-8 grid grid-cols-4 pt-8">
        {/* can't auto flow items since the body of the table also needs to be occupied by this div */}
        <Panel
            className={clsx(
                'col-span-full row-start-2 row-end-[1000] flex flex-col items-center justify-center',
                // match negative margin on panel to positive margin on container
                '-m-8 mb-0'
            )}
        >
            {!savedConfigurations.length && (
                <div className={prose}>
                    No saved configurations.{' '}
                    <Link to="/codedm/create-configuration">
                        Make a new one
                    </Link>
                </div>
            )}
        </Panel>
        <div className={clsx(headerClassName, 'col-start-1')}>
            Configuration Name
        </div>
        <div className={clsx(headerClassName, 'col-start-2')}>Device</div>
        <div className={clsx(headerClassName, 'col-start-3')}>
            Creation Date
        </div>
        <div className={clsx(headerClassName, 'col-start-4')}>
            View QR, Print, More Info
        </div>
        {savedConfigurations.map((c, row, { length }) => (
            <SavedConfigurationItem
                {...c}
                configHierarchy={configHierarchy}
                row={row}
                rowOffset={1}
                key={`${c.name}:${c.device}`}
                isLast={row === length - 1}
                onDelete={onDelete && (() => onDelete(c))}
            />
        ))}
    </div>
)
