import { Button, Panel, QrCode, QrCodeProps } from '@codecorp/components'
import { formatCmdsListForQrCode } from '@codecorp/util/devices/cmds/util'
import { Setting } from './settings-list-content'
import { useBranding } from '../providers/branding-context'
import { ProductDto } from '@codecorp/web-api-wrapper'

export interface IndividualBarcodesCardProps extends Omit<QrCodeProps, 'data'> {
    settings: Setting[]
    selectedDevice: ProductDto
}

export const IndividualBarcodesCard = ({
    settings,
    selectedDevice,
    ...qrCodeProps
}: IndividualBarcodesCardProps) => {
    const brand = useBranding()
    const urlBase = `${import.meta.env.VITE_API_URL}/api/mcodes/report/${
        selectedDevice.productId
    }?branding=${brand === 'codecorp' ? 'CodeCorp' : 'Brady'}`

    let settingsCatalogUrl = urlBase
    settings.forEach((setting) => {
        settingsCatalogUrl = settingsCatalogUrl.concat(
            `&codes=${setting.code.toUpperCase()}`
        )
    })

    return (
        <Panel className="flex h-full w-full flex-col items-center gap-8">
            <div className="max-h-88 m-4 flex w-full flex-row flex-wrap items-start justify-center gap-4 overflow-auto">
                {settings &&
                    settings.map((setting) => {
                        return (
                            <div
                                key={setting.code}
                                className="flex h-fit w-36 flex-col items-center text-center"
                            >
                                <QrCode
                                    {...qrCodeProps}
                                    data={formatCmdsListForQrCode(
                                        ...setting.sourceString
                                    )}
                                    label={`${setting.code}.${setting.revision}`}
                                />
                                <p>{setting.description}</p>
                            </div>
                        )
                    })}
            </div>
            <div className="flex justify-center gap-8 self-stretch">
                {/* <Button
                    className="flex-1"
                    filled
                >
                    Print
                </Button> */}
                <Button
                    className="flex-1"
                    filled
                    as="a"
                    href={settingsCatalogUrl}
                    download
                    target="_blank"
                >
                    Download
                </Button>
            </div>
        </Panel>
    )
}
