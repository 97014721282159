import { ListBulletIcon } from '@radix-ui/react-icons'
import { clsx } from 'clsx'
import { SettingsListCard } from './settings-list-card'
import { useProgressData } from '../providers/progress-data-provider'
import { useCallback } from 'react'
import type { SettingsDto as Setting } from '@codecorp/web-api-wrapper'

const selectionButtonStyle =
    'flex items-center rounded-sm border-2 border-black px-3 py-1 text-xs hover:bg-brand-red hover:text-white group'

const bulletListIconStyle =
    'mr-1 h-3 rounded-sm bg-black text-white group-hover:text-brand-red group-hover:bg-white'

export type { Setting }

export interface SettingsListContentProps {
    settings: Setting[]
}

export const SettingsListContent = ({ settings }: SettingsListContentProps) => {
    const { dispatch } = useProgressData()

    const handleSelectAll = useCallback(() => {
        dispatch({ type: 'multipleSettingsSelected', settings })
    }, [settings, dispatch])

    const handleSelectNone = useCallback(() => {
        dispatch({ type: 'multipleSettingsDeselected', settings })
    }, [settings, dispatch])

    return (
        <>
            <div className="ml-2 flex">
                <button
                    className={selectionButtonStyle}
                    onClick={handleSelectAll}
                >
                    <ListBulletIcon className={bulletListIconStyle} />
                    SELECT ALL
                </button>
                <button
                    className={clsx(selectionButtonStyle, 'ml-4')}
                    onClick={handleSelectNone}
                >
                    <ListBulletIcon className={bulletListIconStyle} />
                    SELECT NONE
                </button>
            </div>
            <div className="my-4 flex justify-between">
                <div className="flex">
                    <p className="ml-6 text-xs font-semibold text-gray-600">
                        Code
                    </p>
                    <p className="ml-12 text-xs font-semibold text-gray-600 md:ml-24">
                        Description
                    </p>
                </div>
                <div className="flex">
                    <p className="mr-3 text-xs font-semibold text-gray-600 md:mr-12">
                        Barcode
                    </p>
                    <p className="mr-6 text-xs font-semibold text-gray-600">
                        Select
                    </p>
                </div>
            </div>
            <ul className="flex flex-col gap-4">
                {settings.map((setting) => {
                    return (
                        <SettingsListCard
                            key={setting.code}
                            setting={setting}
                        />
                    )
                })}
            </ul>
        </>
    )
}
