import { CheckIcon, DashIcon } from '@radix-ui/react-icons'
import * as base from '@radix-ui/react-checkbox'
import clsx from 'clsx'
import { Props } from '../types'
import { Fragment, ReactNode, useId } from 'react'
import { Label } from '@radix-ui/react-label'

export type CheckboxProps = Props<
    {
        /// on change handler that cycles through indeterminate as well as boolean states
        onCheckedIndeterminateChange?: base.CheckboxProps['onCheckedChange']
        /// the default behavior change handler that only cycles through boolean states
        onCheckedChange?: (checked: boolean) => void
        children?: ReactNode
    },
    base.CheckboxProps
>

export const Checkbox = ({
    checked,
    onCheckedIndeterminateChange,
    className,
    children: label,
    id: idProp,
    ...args
}: CheckboxProps) => {
    const idGen = `checkbox-${useId()}`
    const id = idProp || idGen
    const Container = label ? 'div' : Fragment

    return (
        <Container>
            <base.Root
                id={id}
                {...args}
                checked={checked}
                className={clsx(
                    'group inline-flex aspect-square w-5 cursor-pointer items-center justify-center rounded-md border-2 border-gray-700 bg-blue-100 text-blue-600',
                    className
                )}
                onClick={
                    onCheckedIndeterminateChange && checked !== undefined
                        ? () => {
                              const newValue =
                                  checked === 'indeterminate'
                                      ? true
                                      : checked === true
                                      ? false
                                      : 'indeterminate'
                              onCheckedIndeterminateChange(newValue)
                          }
                        : undefined
                }
            >
                <CheckIcon className="group-radix-state-checked:block hidden" />
                <DashIcon className="group-radix-state-indeterminate:block hidden" />
            </base.Root>
            {label && (
                <Label
                    className="pl-3"
                    htmlFor={id}
                >
                    {label}
                </Label>
            )}
        </Container>
    )
}
