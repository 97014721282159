import { ChevronDownIcon } from '@radix-ui/react-icons'
import { ConfigHeader } from '../components/config-header'
import { DeviceSelector } from '../components/device-selector'
import { DiscontinuedDevicesDropdown } from '../components/discontinued-devices-dropdown'
import { useProgressData } from '../providers/progress-data-provider'
import { CategoryChecklist } from '../components/category-checklist'
import { SettingsSelectionPage } from './settings-selection-page'

export const LandingPage = () => {
    const { state } = useProgressData()

    return (
        <div className="flex w-full flex-col justify-center p-4 pt-0 align-top">
            <ConfigHeader progressBarAmount={state.progress} />
            {state.progress === 1 && (
                <>
                    <DeviceSelector />
                    <div className="relative mt-16 mb-8 flex items-center justify-center">
                        <hr className="w-full" />
                        <div className="align-center absolute rounded-full border border-gray-300 bg-white p-2 drop-shadow">
                            <ChevronDownIcon
                                width={20}
                                height={20}
                                color="rgba(0, 0, 0, 0.4)"
                            />
                        </div>
                    </div>
                    <DiscontinuedDevicesDropdown />
                </>
            )}
            {state.progress === 2 && state.device && (
                <CategoryChecklist selectedDevice={state.device} />
            )}
            {state.progress === 3 && state.device && (
                <SettingsSelectionPage selectedDevice={state.device} />
            )}
        </div>
    )
}
