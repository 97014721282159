import clsx from 'clsx'

export interface ProgressBarProps {
    progressBarAmount: number
}

const progressBarSegment =
    'flex h-4 w-full justify-center after:relative after:top-1 after:block after:h-2 after:w-11/12 after:rounded-md after:border-2'

const progressBarDot = (
    <span className="inline-block h-4 w-4 rounded-full bg-white p-2" />
)

// These should probably be made into SVGs at some point
export const ProgressBar = ({ progressBarAmount }: ProgressBarProps) => {
    return (
        <div className="min-w-48 flex w-1/3 justify-center pt-8">
            {[1, 2, 3].map((value) => {
                return (
                    <div
                        className="flex w-full"
                        key={value}
                    >
                        {progressBarDot}
                        <div
                            className={clsx(
                                progressBarSegment,
                                progressBarAmount >= value
                                    ? 'after:bg-white'
                                    : 'bg-transparent'
                            )}
                        />
                    </div>
                )
            })}
            {progressBarDot}
        </div>
    )
}
