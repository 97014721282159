import { Accordion, Button } from '@codecorp/components'
import { CategoryChecklistItem } from './category-checklist-item'
import { useProgressData } from '../providers/progress-data-provider'
import { useCallback, useState } from 'react'

export interface CategoryAccordionProps {
    categories: string[]
    className?: string
}

export const CategoryAccordion = ({
    categories,
    className,
}: CategoryAccordionProps) => {
    const { state, dispatch } = useProgressData()
    const [selectedCategories, setSelectedCategories] = useState<string[]>(
        state.categories
    )
    const [categoryAccordionValue, setCategoryAccordionValue] = useState<
        string[]
    >([])

    const handleSelectChange = useCallback(
        (category: string, isSelected: boolean) => {
            setSelectedCategories((categories) => {
                if (isSelected) {
                    return [...categories, category]
                } else {
                    const i = categories.indexOf(category)
                    return [
                        ...categories.slice(0, i),
                        ...categories.slice(i + 1),
                    ]
                }
            })
        },
        []
    )

    const handleUpdate = useCallback(() => {
        dispatch({ type: 'categoriesSelected', categories: selectedCategories })

        // Kinda hackish but the Radix accordion doesn't have a prop for
        // controlling open/close. Changing the value to an empty array
        // when update is pressed will close the accordion
        setCategoryAccordionValue([])
    }, [dispatch, selectedCategories])

    const items = {
        Category: (
            <>
                {categories.map((category) => {
                    return (
                        <CategoryChecklistItem
                            key={category}
                            className="border-b-2 pb-2"
                            category={category}
                            selectedCategories={selectedCategories}
                            onSelectChange={handleSelectChange}
                        />
                    )
                })}
                <div className="mt-4 flex w-full justify-end">
                    <Button
                        className="w-20"
                        filled
                        onPress={handleUpdate}
                    >
                        Update
                    </Button>
                </div>
            </>
        ),
    }

    return (
        <div className={className}>
            <Accordion
                type="multiple"
                items={items}
                fullWidth
                onValueChange={(value) => setCategoryAccordionValue(value)}
                value={categoryAccordionValue}
            />
        </div>
    )
}
