import { MutableRefObject, Ref, RefCallback, useCallback } from 'react'

const isRefCallback = <T>(ref: Ref<T>): ref is RefCallback<T> =>
    typeof ref === 'function'

const isMutableRefObject = <T>(ref: Ref<T>): ref is MutableRefObject<T> =>
    ref != null && !Object.isFrozen(ref)

const applyValue =
    <T>(current: T) =>
    (ref: Ref<T>) => {
        if (isRefCallback(ref)) {
            ref(current)
        } else if (isMutableRefObject(ref)) {
            ref.current = current
        }
    }

export const useMergedRefs = <T>(...refs: Ref<T>[]) =>
    useCallback(
        (current: T) => refs.forEach(applyValue(current)),
        // don't care if the actual array is the same, just the refs themselves
        // eslint-disable-next-line react-hooks/exhaustive-deps
        refs
    )
