import {
    Option,
    LabeledOption,
    OptionDevice,
    getStateOfBooleanOption,
    getStateOfNumberOption,
    getStateOfOneOfOption,
    getStateOfStringOption,
} from './option-input/option'
import { P, match } from 'ts-pattern'
import { curry } from 'ramda'
import { throw_ } from '@codecorp/util/expression-throw'
import { NotImplementedError } from '@codecorp/util/errors/not-implemented-error'

export type ConfigHierarchy = ConfigSidebarEntry[]

// shown in the left pane
export interface ConfigSidebarParentEntry {
    title: string
    sidebar: ConfigSidebarSectionEntry[]
}

export interface ConfigSidebarSectionEntry {
    title: string
    sections: ConfigSectionEntry[]
}

export type ConfigSidebarEntry =
    | ConfigSidebarParentEntry
    | ConfigSidebarSectionEntry

// shown in the middle pane
export interface ConfigSectionEntry {
    title: string
    options: Option[]
}

const filterSectionToApplicable = (
    device: OptionDevice,
    sections: ConfigSectionEntry[]
) =>
    sections
        .map((section) => {
            const options = section.options.filter(({ applicableDevices }) =>
                applicableDevices.includes(device)
            )
            return options.length
                ? {
                      ...section,
                      options,
                  }
                : null
        })
        .filter(Boolean)

const filterSidebarSectionToApplicable = curry(
    (
        device: OptionDevice,
        section: ConfigSidebarSectionEntry
    ): ConfigSidebarSectionEntry | null => {
        const sections = filterSectionToApplicable(device, section.sections)
        return sections.length
            ? {
                  ...section,
                  sections,
              }
            : null
    }
)

export const filterHierarchyToApplicable = (
    device: OptionDevice,
    hierarchy: ConfigHierarchy
): ConfigHierarchy =>
    hierarchy
        .map((parent) =>
            match(parent)
                .with({ sidebar: P.array() }, (x) => {
                    const sidebar = x.sidebar
                        .map((x) => filterSidebarSectionToApplicable(device, x))
                        .filter(Boolean)

                    return sidebar.length
                        ? {
                              ...parent,
                              sidebar,
                          }
                        : null
                })
                .with({ sections: P.array() }, (x) => {
                    const sections = filterSectionToApplicable(
                        device,
                        x.sections
                    )
                    return sections.length
                        ? {
                              ...parent,
                              sections,
                          }
                        : null
                })
                .exhaustive()
        )
        .filter(Boolean)

const descriptionForOption = curry(
    (o: LabeledOption, cmd: string[]): string | undefined =>
        match(o)
            .with(
                {
                    type: 'boolean',
                },
                (o) => {
                    const state = getStateOfBooleanOption(o, cmd)
                    return state != null
                        ? `${o.label} - ${state ? 'True' : 'False'}`
                        : undefined
                }
            )
            .with({ type: 'oneOf' }, (o) => {
                const state = getStateOfOneOfOption(o, cmd)
                return state != null ? `${o.label} - ${state.name}` : undefined
            })
            .with({ type: 'string' }, (o) => {
                const state = getStateOfStringOption(o, cmd)
                return state != null ? `${o.label} - ${state.value}` : undefined
            })
            .with({ type: 'number' }, (o) => {
                const state = getStateOfNumberOption(o, cmd)
                return state != null ? `${o.label} - ${state}` : undefined
            })
            .with({ type: 'multi' }, (o) => throw_(new NotImplementedError()))
            .exhaustive()
)

const descriptionForCmds = (
    hierarchy: ConfigHierarchy,
    cmds: string[],
    device: OptionDevice
) => {
    const potentialOptions: LabeledOption[] = filterHierarchyToApplicable(
        device,
        hierarchy
    ).flatMap((c) =>
        match(c)
            .with({ sidebar: P.select() }, (x) =>
                x.flatMap((x) =>
                    x.sections.flatMap((s) =>
                        s.options.map((o) => ({ label: s.title, ...o }))
                    )
                )
            )
            .with({ sections: P.select() }, (x) =>
                x.flatMap((s) =>
                    s.options.map((o) => ({ label: s.title, ...o }))
                )
            )
            .exhaustive()
    )

    return potentialOptions
        .map((o) => descriptionForOption(o, cmds))
        .filter(Boolean)
}

export { descriptionForCmds }
