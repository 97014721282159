import { ReactNode, useRef } from 'react'
import { Button, Panel, QrCode, QrCodeProps } from '@codecorp/components'
import { formatCmdsListForQrCode } from '@codecorp/util/devices/cmds/util'
import clsx from 'clsx'
import { omit } from 'ramda'
import { fileSave } from 'browser-fs-access'

interface BaseConfigQrCodeProps extends Omit<QrCodeProps, 'data'> {
    onSave?: () => void
    description: ReactNode
    className?: string
    filename?: string
    showPrintDownloadButtons?: boolean
}
interface CommandConfigQrCodeProps extends BaseConfigQrCodeProps {
    commands: string[]
}
interface DataConfigQrCodeProps extends BaseConfigQrCodeProps {
    data: string
}
export type ConfigQrCodeProps = CommandConfigQrCodeProps | DataConfigQrCodeProps
export const ConfigQrCode = ({
    onSave,
    description,
    className,
    filename,
    showPrintDownloadButtons,
    ...rest
}: ConfigQrCodeProps) => {
    const qrCodeProps = omit(['commands', 'data'], rest)
    const data =
        'data' in rest ? rest.data : formatCmdsListForQrCode(...rest.commands)

    const downloadRef = useRef<HTMLCanvasElement | null>(null)
    const downloadQRCode = async () => {
        if (downloadRef.current) {
            const canvas = downloadRef.current

            // Convert the canvas to a Blob
            canvas.toBlob(async (blob) => {
                if (blob) {
                    try {
                        // Trigger the file save dialog using the fileSave function from browser-fs-access
                        await fileSave(blob, {
                            fileName: filename || 'config.png', // Specify the filename
                            extensions: ['.png'], // Specify allowed extensions
                        })
                    } catch (error) {
                        console.error('Error saving file:', error)
                    }
                }
            }, 'image/png') // Specify the format (PNG)
        }
    }

    const printQRCode = () => {
        if (downloadRef.current) {
            const canvas = downloadRef.current

            // Open a new print window
            const printWindow = window.open('', '_blank')
            if (printWindow) {
                // Write the initial HTML structure and add a title for the print window
                printWindow.document.write(`
                    <html>
                    <head>
                        <title>CodeGen</title>
                        <style>
                            /* Add custom styles for the print window */
                            body {
                                display: flex;
                                flex-direction: column;
                                align-items: center;
                                justify-content: top;
                                height: 100vh;
                                margin: 0;
                                padding: 0;
                            }
                            .canvas-container {
                                margin-bottom: 20px; /* Adjust spacing around the canvas */
                            }
                        </style>
                    </head>
                    <body>
                        <div class="canvas-container">
                            <canvas id="printCanvas"></canvas>
                        </div>

                    </body>
                    </html>
                `)

                printWindow.document.close() // Close the document to apply the changes

                // Wait for the print window to fully load its content before manipulating the canvas
                printWindow.onload = () => {
                    const newCanvas = printWindow.document.getElementById(
                        'printCanvas'
                    ) as HTMLCanvasElement
                    if (newCanvas) {
                        newCanvas.width = canvas.width
                        newCanvas.height = canvas.height

                        const ctx = newCanvas.getContext('2d')
                        if (ctx) {
                            // Copy the drawing from the original canvas to the new canvas
                            ctx.drawImage(canvas, 0, 0)
                        }

                        // Trigger the print dialog
                        printWindow.focus() // Bring the new window to the front
                        printWindow.print() // Trigger the print dialog
                        printWindow.close() // Close the print window after printing
                    }
                }
            }
        }
    }

    return (
        <Panel
            className={clsx(
                className,
                'flex flex-col items-center gap-8 print:m-auto print:h-max print:w-max'
            )}
        >
            <QrCode
                ref={downloadRef}
                {...qrCodeProps}
                data={data}
                label={filename}
            />
            {description}

            {showPrintDownloadButtons !== false && (
                <div className="flex justify-center gap-8 self-stretch print:hidden">
                    <Button
                        className="flex-1"
                        onPress={printQRCode}
                        filled
                    >
                        Print
                    </Button>

                    <Button
                        className="flex-1"
                        onPress={downloadQRCode}
                        filled
                    >
                        Download
                    </Button>
                    {onSave && (
                        <Button
                            className="flex-1"
                            onPress={onSave}
                            filled
                        >
                            Save
                        </Button>
                    )}
                </div>
            )}
        </Panel>
    )
}
