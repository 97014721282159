import { ProgressBar } from './progress-bar'

export interface ConfigHeaderProps {
    progressBarAmount: number
}

export const ConfigHeader = ({ progressBarAmount }: ConfigHeaderProps) => {
    return (
        <section className="mt-4 w-full">
            <div className="min-h-40 flex max-h-fit w-full flex-col items-center bg-[#bababa] p-8 text-center">
                <h1 className="text-2xl font-bold text-white drop-shadow-sm">
                    Welcome, what hardware would you like to configure?
                </h1>
                <ProgressBar progressBarAmount={progressBarAmount} />
            </div>
        </section>
    )
}
