import clsx from 'clsx'
import { DeviceSelectorCard } from './device-selector-card'
import {
    ProductDto,
    useProductGetProductsQuery,
} from '@codecorp/web-api-wrapper'

export const DeviceSelector = () => {
    const { data: allProducts = [] } = useProductGetProductsQuery()

    const filteredProducts = allProducts.filter(
        (product) =>
            !product.isDiscontinued &&
            product.productType === 'Hardware' &&
            product.isConfigurable
    )

    return (
        <section
            className={clsx(
                'mt-4 w-full transition-opacity duration-500',
                allProducts.length > 0 ? 'opacity-100' : 'opacity-0'
            )}
        >
            <div className="grid h-full w-full grid-cols-1 gap-4 sm:grid-cols-4">
                {[...filteredProducts]
                    .sort((a: ProductDto, b: ProductDto) => {
                        if (a.title.includes('V4500')) return -1
                        else return 1
                    })
                    .map((device: ProductDto) => {
                        return (
                            <DeviceSelectorCard
                                key={device.title}
                                device={device}
                            />
                        )
                    })}
            </div>
        </section>
    )
}
