import clsx from 'clsx'
import { BaseProps } from '../types'
import {
    ComponentPropsWithRef,
    createContext,
    forwardRef,
    ReactNode,
    Ref,
    useCallback,
    useContext,
    useEffect,
    useRef,
    useState,
} from 'react'
import { notNull } from '@codecorp/util/expression-throw'

type DialogProps = Omit<
    ComponentPropsWithRef<'dialog'>,
    // managed imperatively and reconciled to allow opening as modal
    'open'
> &
    BaseProps

const Dialog = forwardRef<HTMLDialogElement, DialogProps>(
    ({ className, children, ...props }, ref: Ref<HTMLDialogElement>) => (
        <dialog
            ref={ref}
            {...props}
            className={clsx('overflow-visible bg-transparent', className)}
        >
            <form method="dialog">{children}</form>
        </dialog>
    )
)

export interface ModalShowOptions {
    modal?: boolean
    content: Exclude<ReactNode, null | undefined | boolean>
    dialogClassName?: string
}

export interface ModalContextValue {
    show: (options: ModalShowOptions) => Promise<string>
    close: () => void
}

const ModalContext = createContext<ModalContextValue | null>(null)

export interface ModalProviderProps {
    children?: ReactNode
}
export const ModalProvider = ({ children }: ModalProviderProps) => {
    // set to null to close, but use the imperative show from hooks to actually show it
    const [dialogContent, setDialogContent] = useState<DialogProps | null>(null)
    const dialogRef = useRef<HTMLDialogElement>(null)

    const show = useCallback(
        ({ content, modal = false, dialogClassName }: ModalShowOptions) => {
            const dialog = notNull(dialogRef.current)
            setDialogContent({ children: content, className: dialogClassName })

            return new Promise<string>((resolve) => {
                if (dialog.open) throw new Error('Dialog is already open')

                dialog.returnValue = ''
                dialog.addEventListener(
                    'close',
                    () => resolve(dialog.returnValue),
                    { once: true }
                )

                if (modal) dialog.showModal()
                else dialog.show()
            })
        },
        [setDialogContent]
    )
    const close = useCallback(() => setDialogContent(null), [setDialogContent])

    useEffect(() => {
        if (!dialogContent) dialogRef.current?.close()
    }, [dialogContent])

    return (
        <ModalContext.Provider value={{ show, close }}>
            {children}
            <Dialog
                ref={dialogRef}
                {...dialogContent}
            />
        </ModalContext.Provider>
    )
}

export const useModal = () =>
    notNull(useContext(ModalContext), {
        msg: "useModal called outside of a ModalProvider's context",
    })
