import { configureStore } from '@reduxjs/toolkit'
import {
    fetchBaseQuery,
    setupListeners as setupRtkqListeners,
} from '@reduxjs/toolkit/query/react'
import { codeWebApi, setFetch } from '@codecorp/web-api-wrapper'
import { pick } from 'ramda'

setFetch(
    fetchBaseQuery({
        baseUrl: import.meta.env.VITE_API_URL,
    })
)

export const store = configureStore({
    reducer: {
        [codeWebApi.reducerPath]: codeWebApi.reducer,
    },

    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(codeWebApi.middleware),
    devTools: {
        actionCreators: {
            ...pick(['resetApiState', 'invalidateTags'], codeWebApi.util),
        },
    },
})

setupRtkqListeners(store.dispatch)

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
