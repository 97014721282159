import { ProductDto } from '@codecorp/web-api-wrapper'
import { Setting } from '../components/settings-list-content'
import { produce, enableMapSet } from 'immer'

enableMapSet()

export interface ProgressDataState {
    progress: number
    device: ProductDto | null
    categories: string[]
    settings: Set<Setting>
}

export type ProgressDataAction =
    | {
          type: 'deviceSelected'
          device: ProductDto
      }
    | {
          type: 'categoriesSelected'
          categories: string[]
      }
    | {
          type: 'userRestarted'
      }
    | {
          type: 'backButtonPressed'
      }
    | {
          type: 'settingSelected'
          setting: Setting
      }
    | {
          type: 'settingDeselected'
          setting: Setting
      }
    | {
          type: 'multipleSettingsSelected'
          settings: Setting[]
      }
    | {
          type: 'multipleSettingsDeselected'
          settings: Setting[]
      }

export const reducer = produce(
    (
        state: ProgressDataState,
        action: ProgressDataAction
    ): ProgressDataState => {
        switch (action.type) {
            case 'deviceSelected':
                return {
                    ...state,
                    device: action.device,
                    progress:
                        state.progress < 3
                            ? state.progress + 1
                            : state.progress,
                }
            case 'categoriesSelected':
                return {
                    ...state,
                    categories: action.categories,
                    progress:
                        state.progress < 3
                            ? state.progress + 1
                            : state.progress,
                }
            case 'userRestarted':
                return initialState
            case 'backButtonPressed':
                return {
                    ...state,
                    categories: [],
                    settings: new Set(),
                    progress: state.progress === 2 ? 1 : 2,
                }
            case 'settingSelected':
                state.settings.add(action.setting)
                return state
            case 'settingDeselected':
                state.settings.delete(action.setting)
                return state
            case 'multipleSettingsSelected':
                action.settings.forEach((s) => state.settings.add(s))
                return state
            case 'multipleSettingsDeselected':
                action.settings.forEach((s) => state.settings.delete(s))
                return state
            default:
                return state
        }
    }
)

export const initialState: ProgressDataState = {
    progress: 1,
    device: null,
    categories: [],
    settings: new Set(),
}
