import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { notNull } from '@codecorp/util/expression-throw'

let currentQuery: ReturnType<typeof fetchBaseQuery>

export const setFetch = (newQuery: typeof currentQuery) => {
    currentQuery = newQuery
}

export const baseQuery: ReturnType<typeof fetchBaseQuery> = (...args) =>
    notNull(currentQuery, { msg: 'baseQuery not initialized' })(...args)
