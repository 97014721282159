import * as base from '@radix-ui/react-radio-group'
import clsx from 'clsx'
import { ComponentRef, forwardRef } from 'react'
import { Props } from '../../types'

export type RadioContainerProps = Props<
    Record<never, never>,
    base.RadioGroupProps
>

export const RadioContainer = forwardRef<
    ComponentRef<typeof base.Root>,
    RadioContainerProps
>(({ className, ...props }, ref) => (
    <base.Root
        {...props}
        ref={ref}
        className={clsx`${className} radix-orientation-horizontal:flex-row radix-orientation-vertical:flex-col group flex gap-5`}
    />
))
