import { ComponentRef, ReactNode, forwardRef } from 'react'
import { Props } from '../../types'
import { RadioContainer, RadioContainerProps } from './container'
import { RadioButton } from './radio-button'

export interface RadioGroupItem {
    label: ReactNode
    value: string
}

export type RadioGroupProps = Props<
    {
        items: RadioGroupItem[]
    },
    Omit<RadioContainerProps, 'children' | 'asChild'>
>

export const RadioGroup = forwardRef<
    ComponentRef<typeof RadioContainer>,
    RadioGroupProps
>(({ items, ...props }, ref) => (
    <RadioContainer
        {...props}
        ref={ref}
    >
        {items.map(({ value, label }) => (
            <RadioButton
                key={value}
                value={value}
            >
                {label}
            </RadioButton>
        ))}
    </RadioContainer>
))
