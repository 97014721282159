import { lazy } from 'react'

// pulls emotion and styled-components, only used here, and the time to load this should get eaten by loading the actual data
export const DataTable = lazy(async () => {
    const { default: DataTable, createTheme } = await import(
        'react-data-table-component'
    )

    createTheme(
        'transparent-dark',
        {
            background: {
                default: 'transparent',
            },
        },
        'dark'
    )

    createTheme(
        'transparent-light',
        {
            background: {
                default: 'transparent',
            },
        },
        'light'
    )

    return { default: DataTable }
})
