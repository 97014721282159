import { Checkbox } from '@codecorp/components'
import { CheckedState } from '@radix-ui/react-checkbox'
import { useRef, useState } from 'react'
import { clsx } from 'clsx'
import { useProgressData } from '../providers/progress-data-provider'
import { useButton } from '@react-aria/button'

export interface CategoryChecklistItemProps {
    category: string
    selectedCategories: string[]
    onSelectChange: (category: string, isSelected: boolean) => void
    className?: string
}

export const CategoryChecklistItem = ({
    category,
    selectedCategories,
    onSelectChange,
    className,
}: CategoryChecklistItemProps) => {
    const { state } = useProgressData()
    const [isChecked, setIsChecked] = useState<CheckedState>(
        state.categories.includes(category)
    )
    const ref = useRef(null)
    const { buttonProps } = useButton({ elementType: 'li' }, ref)

    const handleSelect = () => {
        const shouldBeSelected = !selectedCategories.includes(category)
        setIsChecked(shouldBeSelected)
        onSelectChange(category, shouldBeSelected)
    }

    return (
        <div
            ref={ref}
            {...buttonProps}
            className={clsx(
                'my-4 flex items-center justify-between',
                className
            )}
            onClick={handleSelect}
        >
            {category}
            <Checkbox
                className="h-5 w-5 border-gray-300"
                checked={isChecked}
            />
        </div>
    )
}
