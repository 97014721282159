import clsx from 'clsx'
import { PolymorphicPropsWithoutRef } from 'react-polymorphic-types'
import type { Props } from '../types'
import { withoutUndefinedValues } from '../util/without-undefined-keys'
import { ElementType, ForwardedRef, forwardRef } from 'react'

const DefaultContainer = 'div'

type OwnProps = Props<{
    /// don't add any extra padding
    dense?: boolean
    /// Add extra padding to the x axis
    wide?: boolean
    /// Smaller rounded corners
    smallCorners?: boolean
}>

export type PanelProps<T extends ElementType> = PolymorphicPropsWithoutRef<
    OwnProps,
    T
>

export const Panel = forwardRef(
    <TContainer extends ElementType = typeof DefaultContainer>(
        {
            className,
            dense = false,
            wide = false,
            smallCorners = false,

            as,
            ...props
        }: PanelProps<TContainer>,
        // react-polymorphic-types doesn't support forwardRef in current ts, and isn't in maintained anymore
        // need to switch to something else, probably a combination of radix slots and render props
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ref: ForwardedRef<any>
    ) => {
        const Container = as ?? DefaultContainer
        return (
            <Container
                ref={ref}
                {...withoutUndefinedValues(props)}
                className={clsx(
                    'drop-shadow-ring bg-white dark:bg-slate-700',
                    dense || 'p-8',
                    wide && 'px-32',
                    smallCorners ? 'rounded-lg' : 'rounded-2xl',
                    className
                )}
            />
        )
    }
)
