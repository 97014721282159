import { Accordion } from '@codecorp/components'
import { ReactNode, useMemo, useState } from 'react'
import { SettingsListContent } from './settings-list-content'
import {
    ProductDto,
    useSettingsGetCategorySettingsQuery,
} from '@codecorp/web-api-wrapper'

export interface SettingsListProps {
    category: string
    selectedDevice: ProductDto
    searchTerm: string
}

export const SettingsList = ({
    category,
    selectedDevice,
    searchTerm,
}: SettingsListProps) => {
    const { data: allSettings = [] } = useSettingsGetCategorySettingsQuery({
        id: selectedDevice.productId,
        category: category,
    })
    const [accordionValue, setAccordionValue] = useState([category])

    const filteredSettings = allSettings.filter(
        (setting) =>
            setting.code.toLowerCase().includes(searchTerm) ||
            setting.description.toLowerCase().includes(searchTerm)
    )

    const listItems = useMemo(() => {
        const items: Record<string, ReactNode> = {}
        items[category] = <SettingsListContent settings={filteredSettings} />
        return items
    }, [category, filteredSettings])

    return (
        <Accordion
            className="-mx-4 mb-4 max-w-[unset] sm:mx-0 sm:max-w-full"
            type="multiple"
            items={listItems}
            value={accordionValue}
            onValueChange={(value) => setAccordionValue(value)}
        />
    )
}
